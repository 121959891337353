<template>
  <fragment>
    <page-header :title="$t('settings.title')" icon="settings" extended>
      <v-tabs v-model="tab" background-color="transparent" align-with-title>
        <v-tab to="/settings/general">
          <v-icon left>dashboard</v-icon>
          {{ $t("settings.tabs.general") }}
        </v-tab>
        <v-tab to="/settings/amazon">
          <v-icon left>cc-icon-vendor-aws</v-icon>
          {{ $t("settings.tabs.amazon") }}
        </v-tab>
        <v-tab to="/settings/azure">
          <v-icon left>cc-icon-vendor-azure</v-icon>
          {{ $t("settings.tabs.azure") }}
        </v-tab>
        <v-tab to="/settings/google" v-if="isGoogleEnabled">
          <v-icon left>cc-icon-vendor-gcp</v-icon>
          {{ $t("settings.tabs.google") }}
        </v-tab>
        <v-tab to="/settings/alibaba" v-if="isAlibabaEnabled">
          <v-icon left>cc-icon-vendor-alicloud</v-icon>
          {{ $t("settings.tabs.alibaba") }}
        </v-tab>
        <v-tab to="/settings/oracle" v-if="isOracleEnabled">
          <v-icon left>cc-icon-vendor-oci</v-icon>
          {{ $t("settings.tabs.oracle") }}
        </v-tab>
        <v-tab to="/settings/snowflake" v-if="isSnowflakeEnabled">
          <v-icon left>cc-icon-vendor-snowflake</v-icon>
          {{ $t("settings.tabs.snowflake") }}
        </v-tab>
        <v-tab to="/settings/custom" v-if="isCustomAccountsEnabled">
          <v-icon left>publish</v-icon>
          {{ $t("settings.tabs.custom") }}
        </v-tab>
        <v-tab to="/settings/users">
          <v-icon left>people</v-icon>
          {{ $t("settings.tabs.users") }}
        </v-tab>
        <v-tab to="/settings/mailinglists">
          <v-icon left>publish</v-icon>
          {{ $t("settings.tabs.mailinglists") }}
        </v-tab>
        <v-tab to="/settings/billing">
          <v-icon left>credit_card</v-icon>
          {{ $t("settings.tabs.billing") }}
        </v-tab>
        <v-tab to="/settings/apimanagement">
          <v-icon left>vpn_key</v-icon>
          {{ $t("settings.tabs.apimanagement") }}
        </v-tab>
        <v-tab to="/settings/tagmappings" v-if="isTagMappingsEnabled">
          <v-icon left>sell</v-icon>
          {{ $t("settings.tabs.tagmappings") }}
        </v-tab>
        <v-tab to="/settings/actions" v-if="isActionsEnabled">
          <v-icon left>code</v-icon>
          {{ $t("settings.tabs.actions") }}
        </v-tab>
        <v-tab to="/settings/fxrates">
          <v-icon left>currency_exchange</v-icon>
          {{ $t("settings.tabs.fxrates") }}
        </v-tab>
        <v-tab to="/settings/dimensions">
          <v-icon left>sell</v-icon>
          {{ $t("settings.tabs.dimensions") }}
        </v-tab>
        <v-tab to="/settings/webhooks">
          <v-icon left>bolt</v-icon>
          {{ $t("settings.tabs.webhooks") }}
        </v-tab>
      </v-tabs>
    </page-header>
    <v-container>
      <router-view
        :cloud-accounts="cloudAccounts"
        @onCloudAccountsChanged="getCloudAccounts"
      ></router-view>
    </v-container>
  </fragment>
</template>

<i18n>
{
    "en": {
        "settings.title": "Settings",
        "settings.tabs.amazon": "Amazon",
        "settings.tabs.azure": "Azure",
        "settings.tabs.google": "Google",
        "settings.tabs.oracle": "Oracle",
        "settings.tabs.alibaba": "Alibaba",
        "settings.tabs.snowflake": "Snowflake",
        "settings.tabs.users": "Users",
        "settings.tabs.general": "General",
        "settings.tabs.billing": "Billing",
        "settings.tabs.apimanagement": "Api Keys",
        "settings.tabs.actions": "Actions",
        "settings.tabs.custom":"Custom",
        "settings.tabs.fxrates":"FX Rates",
        "settings.tabs.tagmappings": "Mapping Rules",
        "settings.tabs.mailinglists": "Mailing Lists",
        "settings.tabs.webhooks": "WebHooks",
        "settings.tabs.dimensions": "Dimensions"
    }
}
</i18n>

<script>
import ApiV2 from "@/lib/ApiV2";
import { waitFor } from "@/plugins/vue-wait";
import { useTenantStore } from "../../stores/tenant.ts";
import { mapStores } from "pinia";
import Config from "@/lib/Config";
const isCloudAccountActionsEnabled = Config.isFeatureEnabled("CloudAccountActions");
const isCustomAccountsEnabled = Config.isFeatureEnabled("CustomCloudAccounts");
export default {
  data() {
    return {
      tab: null,
      drawer: true,
      cloudAccounts: [],
    };
  },
  methods: {
    getCloudAccounts: waitFor("getCloudAccounts", async function () {
      this.cloudAccounts = await ApiV2.http
        .get("/api/metadata/cloudaccounts")
        .then((r) => r.data);
    }),
  },
  created() {
    this.getCloudAccounts();
  },
  computed: {
    isActionsEnabled() {
      return (
        isCloudAccountActionsEnabled &&
        this.tenantStore.hasTenantFeature("CloudAccountActions")
      );
    },
    isCustomAccountsEnabled() {
      const isCustomAccountsEnabledForTenant = this.tenantStore.hasTenantFeature(
        "CustomCloudAccounts"
      );
      return isCustomAccountsEnabled && isCustomAccountsEnabledForTenant;
    },
    isTagMappingsEnabled() {
      return this.tenantStore.hasTenantFeature("MappingRules");
    },
    isSnowflakeEnabled() {
      return this.tenantStore.hasTenantFeature("Snowflake");
    },
    isOracleEnabled() {
      return !this.tenantStore.hasTenantFeature("Pax8Isolated")
    },
    isAlibabaEnabled() {
      return !this.tenantStore.hasTenantFeature("Pax8Isolated")
    },
    isGoogleEnabled() {
      return !this.tenantStore.hasTenantFeature("Pax8Isolated")
    },
    ...mapStores(useTenantStore),
  },
};
</script>
