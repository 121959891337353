
import { defineComponent, ref } from 'vue';
import { getWebhooks, createWebhook } from '@/lib/Api';
import UpdateEntityNameCard from '@/components/Common/UpdateEntityNameCard.vue';

interface IWebhookViewModel {
    id: number;
    name: string;
    url: string;
    lastcalled: string;
    enabled: boolean;
}

export default defineComponent({
    computed: {
        headers() {
            return [
                {
                    text: this.$t("name"),
                    value: "name",
                    sortable: true,
                },
                {
                    text: this.$t("views.webhooks.url"),
                    value: "url",
                    sortable: false,
                },
                {
                    text: this.$t("views.webhooks.lastcalled"),
                    value: "lastCalled",
                    sortable: false,
                },
                {
                    text: this.$t("views.webhooks.enabled"),
                    value: "enabled",
                    sortable: false,
                },
                {
                    text: this.$t('actions'),
                    value: "Actions",
                    sortable: false
                }
            ];
        },
    },
    components: { UpdateEntityNameCard },
    setup() {
        const webhooks = ref<IWebhookViewModel[]>([]);
        const isCreatingOrEditing = ref(false);
        const targetWebhook = ref<Partial<IWebhookViewModel>>({});
        const loading = ref(false);
        const getPageData = async () => {
            try {
                loading.value = true;
                webhooks.value = await getWebhooks();
            } finally {
                loading.value = false;
            }
        };
        getPageData();
        const submitNameForm = async (data: { Name: string }) => {
            try {
                loading.value = true;
                if (targetWebhook.value.id) {
                    //     await putWebhook({
                    //         id: targetWebhook.value.id,
                    //         name: data.Name,
                    //         url: targetWebhook.value.url,
                    //         lastcalled: targetWebhook.value.enabled,
                    //     });
                } else {
                    await createWebhook({
                        id: -1,
                        name: data.Name,
                        url: null,
                        enabled: true,
                        lastcalled: null
                    });
                }
            } finally {
                loading.value = false;
            }
            await getPageData();
        };
        const startCreate = () => {
            targetWebhook.value = {};
            isCreatingOrEditing.value = true;
        };
        const startEditing = (body: IWebhookViewModel) => {
            targetWebhook.value = body;
            isCreatingOrEditing.value = true;
        };
        return {
            webhooks,
            getPageData,
            isCreatingOrEditing,
            targetWebhook,
            submitNameForm,
            startCreate,
            startEditing,
            loading,
        };
    },
});
