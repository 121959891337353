
import { defineComponent, ref, watch } from 'vue';
import { useTenantStore, useWaitStore } from '@/stores';
import { mapStores } from 'pinia';
import { IDeltaSpendStrategyModel, INewSpendStrategyModel, INewAccountStrategyModel, IRegionalSpendStrategyModel, IAverageSpendStrategyModel, CostView } from '@/models/IPolicyModel'
import { createPolicy, putPolicy, listDimensions, getMailingLists, getCustomViews, getPolicy, listPolicyResults } from '@/lib/Api';
import ContentCard from '@/components/Common/ContentCard.vue';

interface IListItem {
    value: string;
    text: string;
}

export default defineComponent({
    components: { ContentCard },
    title(ctx) {
        return ctx.$t('newPolicy.title');
    },
    computed: {
        ...mapStores(useTenantStore),
        scheduleDayLabel() {
            if (this.schedule === 'EveryImport') return '';
            return this.schedule === 'Weekly' ? 'Schedule Day of the Week (Sunday = 0)' : 'Schedule Day of the Month';
        },
        lookBackPeriodLabel() {
            if (!this.comparisonPeriod) return '';
            return this.comparisonPeriod === 'Day' ? 'Look-back Days' : this.comparisonPeriod === 'Week' ? 'Look-back Weeks' : 'Look-back Months';
        },
    },
    methods: {
        back() {
            this.$router.push('/watchdog');
        },
    },
    setup() {
        const { is } = useWaitStore();
        const formValid = ref(false);

        const editId = ref(0);

        const policyName = ref('');
        const policyLevel = ref('Critical');
        const policyLevels = ref<IListItem[]>([
            { value: 'Critical', text: 'Critical' },
            { value: 'Warning', text: 'Warning' },
            { value: 'Information', text: 'Informational' },
        ]);
        const dimension = ref('');
        const dimensions = ref<IListItem[]>([]);
        const strategy = ref('');
        const strategies = ref<IListItem[]>([
            { value: 'AverageSpend', text: 'Delta Average Spend By Dimension' },
            { value: 'DeltaSpend', text: 'Delta Spend By Dimension' },
            { value: 'NewSpend', text: 'Introduced Spend By Dimension' },
            { value: 'NewAccount', text: 'New Account By Dimension' },
            { value: 'RegionalSpend', text: 'Multi Region Spend By Dimension' }
        ]);
        const compareType = ref('Product');
        const compareTypes = ref<IListItem[]>([
            { value: 'Product', text: 'Products' },
            { value: 'ProductCategory', text: 'Product Categories' },
            { value: 'Region', text: 'Regions' }
        ]);
        const granularity = ref('Day');
        const granularities = ref<IListItem[]>([
            { value: 'Day', text: 'Day' },
            { value: 'Week', text: 'Week' },
            { value: 'Month', text: 'Month' },
        ])
        const countComparisonPeriod = ref(1);
        const comparisonPeriod = ref('Day');
        const comparisonPeriodTypes = ref<IListItem[]>([
            { value: 'Day', text: 'Day(s)' },
            { value: 'Week', text: 'Week(s)' },
            { value: 'Month', text: 'Month(s)' },
        ]);
        const lineItemType = ref('UsagePlusFees');
        const lineItemTypes = ref<IListItem[]>([
            { value: 'UsageOnly', text: 'Usage Only' },
            { value: 'UsagePlusFees', text: 'Usage + Fees' },
            { value: 'FeesOnly', text: 'Fees Only' }
        ]);
        const triggerMinDeltaPercent = ref(20);
        const triggerMinDeltaAmount = ref(100);
        const averageOverDays = ref(30);
        const triggerMinNewAmount = ref(100);
        const triggerMinUsageAmount = ref(1000);
        const triggerMinRegionCount = ref(3);
        const scheduleFreqQty = ref(1);
        const scheduleDay = ref(1);
        const schedule = ref('EveryImport');
        const schedules = ref<IListItem[]>([
            { value: 'EveryImport', text: 'Twice daily @ 00:00 UTC & 12:00 UTC' },
            { value: 'Weekly', text: 'Weekly' },
            { value: 'Monthly', text: 'Monthly' },
        ]);
        const mailingList = ref(null);
        const mailingLists = ref<IListItem[]>([]);
        const emailRecipients = ref([]);

        const customViewId = ref(null);
        const customViews = ref<IListItem[]>([]);

        const isLoading = ref(false);
        const loadPageData = async () => {
            try {
                isLoading.value = true;
                const [dims, mls, cvs] = await Promise.all([listDimensions(), getMailingLists(), getCustomViews()]);
                dimensions.value = dims.map((d) => ({
                    value: d.id.toString(),
                    text: d.label,
                }));
                mailingLists.value = mls.map((m) => ({
                    value: m.id.toString(),
                    text: m.name,
                }));
                customViews.value = cvs.map((cv) => ({
                    value: cv.id,
                    text: cv.name,
                }));
            } finally {
                isLoading.value = false;
            }
        };
        loadPageData();
        const isSubmitting = ref(false);
        const trySubmitForm = async () => {
            try {
                isSubmitting.value = true;
                let averageSpendStrategy: IAverageSpendStrategyModel;
                let deltaSpendStrategy: IDeltaSpendStrategyModel;
                let newSpendStrategy: INewSpendStrategyModel;
                let newAccountStrategy: INewAccountStrategyModel;
                let regionalSpendStrategy: IRegionalSpendStrategyModel;
                if (strategy.value == "AverageSpend") {
                    newSpendStrategy = null;
                    regionalSpendStrategy = null;
                    deltaSpendStrategy = null;
                    newAccountStrategy = null;
                    averageSpendStrategy = {
                        comparePeriod: comparisonPeriod.value,
                        comparePeriodQuantity: countComparisonPeriod.value,
                        granularity: granularity.value,
                        averageOverDays: averageOverDays.value,
                        triggerMinDeltaAmount: triggerMinDeltaAmount.value,
                        triggerMinDeltaPercent: triggerMinDeltaPercent.value,
                        description: null,
                        lineItemType: lineItemType.value,
                    };
                }

                if (strategy.value == "DeltaSpend") {
                    newSpendStrategy = null;
                    regionalSpendStrategy = null;
                    newAccountStrategy = null;
                    deltaSpendStrategy = {
                        comparePeriod: comparisonPeriod.value,
                        comparePeriodQuantity: countComparisonPeriod.value,
                        granularity: granularity.value,
                        serviceName: null,
                        triggerMinDeltaAmount: triggerMinDeltaAmount.value,
                        triggerMinDeltaPercent: triggerMinDeltaPercent.value,
                        description: null,
                        lineItemType: lineItemType.value,
                    };
                }

                if (strategy.value == "NewSpend") {
                    deltaSpendStrategy = null;
                    regionalSpendStrategy = null;
                    newAccountStrategy = null;
                    newSpendStrategy = {
                        compareType: compareType.value,
                        comparePeriod: comparisonPeriod.value,
                        comparePeriodQuantity: countComparisonPeriod.value,
                        granularity: granularity.value,
                        triggerMinNewAmount: triggerMinNewAmount.value,
                        description: null,
                        lineItemType: lineItemType.value,
                    };
                }

                if (strategy.value == "NewAccount") {
                    deltaSpendStrategy = null;
                    regionalSpendStrategy = null;
                    newSpendStrategy = null;
                    newAccountStrategy = {
                        comparePeriod: comparisonPeriod.value,
                        comparePeriodQuantity: countComparisonPeriod.value,
                        triggerMinNewAmount: triggerMinNewAmount.value,
                        description: null,
                        lineItemType: lineItemType.value,
                    };
                }

                if (strategy.value == "RegionalSpend") {
                    deltaSpendStrategy = null;
                    newSpendStrategy = null;
                    newAccountStrategy = null;
                    regionalSpendStrategy = {
                        comparePeriod: comparisonPeriod.value,
                        comparePeriodQuantity: countComparisonPeriod.value,
                        triggerMinUsageAmount: triggerMinUsageAmount.value,
                        triggerMinRegionCount: triggerMinRegionCount.value,
                        description: null,
                        lineItemType: lineItemType.value,
                    };
                }

                const policyRequest = {
                    dimension: { id: Number(dimension.value), label: '', tagKey: '', index: 0 },
                    id: editId.value > 0 ? editId.value : 0,
                    name: policyName.value,
                    severity: policyLevel.value,
                    strategySummary: null,
                    description: null,
                    lastRun: null,
                    lastRunRecordCount: 0,
                    updatedDate: new Date(),
                    lineItemType: null,
                    deltaSpendStrategy: deltaSpendStrategy,
                    newSpendStrategy: newSpendStrategy,
                    regionalSpendStrategy: regionalSpendStrategy,
                    averageSpendStrategy: averageSpendStrategy,
                    newAccountStrategy: newAccountStrategy,
                    schedule: {
                        id: 0,
                        description: '',
                        emailNotifyList: emailRecipients.value.join(','),
                        frequency: schedule.value,
                        frequencyQuantity: scheduleFreqQty.value,
                        nextScheduled: new Date(),
                        day: scheduleDay.value,
                    },
                    mailingList: {
                        id: mailingList.value,
                        name: '',
                        emails: [],
                    },
                    customView: {
                        id: customViewId.value ?? -1,
                    },
                }

                if (editId.value > 0)
                    return await putPolicy(policyRequest);
                else
                    return await createPolicy(policyRequest);
            } finally {
                isSubmitting.value = false;
            }
        };

        const loadPolicyDataForEdit = async (id: number) => {
            const [policyResp, resultsResp] = await Promise.all([getPolicy(id), listPolicyResults(id)]);

            policyName.value = policyResp.name;
            policyLevel.value = policyResp.severity;
            customViewId.value = policyResp.customView?.id;
            dimension.value = policyResp.dimension?.id.toString();
            schedule.value = policyResp.schedule.frequency;
            scheduleFreqQty.value = policyResp.schedule.frequencyQuantity;
            scheduleDay.value = policyResp.schedule.day;
            mailingList.value = policyResp.mailingList?.id.toString();

            if (policyResp.newSpendStrategy) {
                strategy.value = "NewSpend";
                lineItemType.value = policyResp.newSpendStrategy.lineItemType;
                triggerMinNewAmount.value = policyResp.newSpendStrategy.triggerMinNewAmount;
                compareType.value = policyResp.newSpendStrategy.compareType;
                granularity.value = policyResp.newSpendStrategy.granularity;
                comparisonPeriod.value = policyResp.newSpendStrategy.comparePeriod;
                countComparisonPeriod.value = policyResp.newSpendStrategy.comparePeriodQuantity;
            }
            else if (policyResp.newAccountStrategy) {
                strategy.value = "NewAccount";
                lineItemType.value = policyResp.newAccountStrategy.lineItemType;
                triggerMinNewAmount.value = policyResp.newAccountStrategy.triggerMinNewAmount;
                comparisonPeriod.value = policyResp.newAccountStrategy.comparePeriod;
                countComparisonPeriod.value = policyResp.newAccountStrategy.comparePeriodQuantity;
            }
            else if (policyResp.regionalSpendStrategy) {
                strategy.value = "RegionalSpend";
                lineItemType.value = policyResp.regionalSpendStrategy.lineItemType;
                triggerMinUsageAmount.value = policyResp.regionalSpendStrategy.triggerMinUsageAmount;
                triggerMinRegionCount.value = policyResp.regionalSpendStrategy.triggerMinRegionCount;
                comparisonPeriod.value = policyResp.regionalSpendStrategy.comparePeriod;
                countComparisonPeriod.value = policyResp.regionalSpendStrategy.comparePeriodQuantity;
            }
            else if (policyResp.deltaSpendStrategy) {
                strategy.value = "DeltaSpend";
                lineItemType.value = policyResp.deltaSpendStrategy.lineItemType;
                triggerMinDeltaPercent.value = policyResp.deltaSpendStrategy.triggerMinDeltaPercent;
                triggerMinDeltaAmount.value = policyResp.deltaSpendStrategy.triggerMinDeltaAmount;
                granularity.value = policyResp.deltaSpendStrategy.granularity;
                comparisonPeriod.value = policyResp.deltaSpendStrategy.comparePeriod;
                countComparisonPeriod.value = policyResp.deltaSpendStrategy.comparePeriodQuantity;
            }
            else if (policyResp.averageSpendStrategy) {
                strategy.value = "AverageSpend";
                lineItemType.value = policyResp.averageSpendStrategy.lineItemType;
                averageOverDays.value = policyResp.averageSpendStrategy.averageOverDays;
                triggerMinDeltaPercent.value = policyResp.averageSpendStrategy.triggerMinDeltaPercent;
                triggerMinDeltaAmount.value = policyResp.averageSpendStrategy.triggerMinDeltaAmount;
                granularity.value = policyResp.averageSpendStrategy.granularity;
                comparisonPeriod.value = policyResp.averageSpendStrategy.comparePeriod;
                countComparisonPeriod.value = policyResp.averageSpendStrategy.comparePeriodQuantity;
            }
        };

        return {
            formValid,
            policyName,
            policyLevel,
            policyLevels,
            dimension,
            dimensions,
            strategy,
            strategies,
            compareType,
            compareTypes,
            scheduleFreqQty,
            scheduleDay,
            schedule,
            schedules,
            granularity,
            granularities,
            countComparisonPeriod,
            comparisonPeriod,
            comparisonPeriodTypes,
            lineItemType,
            lineItemTypes,
            triggerMinDeltaPercent,
            triggerMinDeltaAmount,
            averageOverDays,
            triggerMinNewAmount,
            triggerMinUsageAmount,
            triggerMinRegionCount,
            mailingList,
            mailingLists,
            emailRecipients,
            isSubmitting,
            editId,
            trySubmitForm,
            loadPolicyDataForEdit,
            isLoading,
            customViewId,
            customViews,
            wait: { is },
        };
    },
    watch: {
        $route: {
            handler(val) {
                if (!val) return;
                if (!val.params.id) return;

                this.editId = Number(val.params.id);
                this.loadPolicyDataForEdit(this.editId);
            },
            immediate: true,
            deep: true,
        },
    },
});
